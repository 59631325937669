


  /*  Fuente principal*/
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body,
html {
  font-family: Barlow !important;

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}


// componente de deshabilitado global
.deshabilitadoBR8{
  content: "";   
  filter: blur(1.6px) !important; /* Aplica el efecto de desenfoque */ 
  pointer-events: none !important; 
  
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(174, 174, 174, 0.5) !important;
    // background-color: rgba(176, 136, 94, 0.5) !important;
    pointer-events: none !important; 
    image-rendering: pixelated; 
    border-radius: 8px; 
  }
}

// Quitando borde a polar highchart
g.highcharts-axis.highcharts-xaxis.highcharts-radial-axis {
  display: none !important;
}


/* PALETA DE COLORES MODO LIGHT:*/
$color_title: #464255;
$color_text: #969BA0;
$color_main_degradado: linear-gradient(180deg, #F49742 0%, #EDC418 100%);
$color_main_degradado_h: linear-gradient(90deg, #F49742 0%, #EDC418 100%);
$color_main: #F0B527;
$color_main_list: #ffd677;
$color_advanceicons: #4e4e4e;
$color_alterno: #fffaf0;



/* PALETA DE COLORES HIGHCHART:*/
$color_derecho: red;
$color_izquierdo: blue;
$color_derecho: orange;




/* SOMBRAS */
$main_shadow: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.222));






// #########################
// FUENTES:
// #########################



text{
  font-family: Barlow;
}

g.highcharts-legend-item text {
  font-weight: 400 !important;
}

g.highcharts-axis-labels {
  font-weight: 400 !important;
}

// Titulo página
.tituloPagina{
  font-size: 32px;
  color: $color_title;
  font-weight: 700;
}

.textoPagina{
  font-size: 18px;
  color: $color_title;
  font-weight: 400;

}

// Titulo tarjetas
.tituloTarjeta{
  font-size: 20px;
  color: $color_title;
  font-weight: 700;
}

.tituloElemento{
  font-size: 18px;
  color: $color_title;
  font-weight: 700;

}

.textoTarjeta{
  font-size: 16px;
  color: $color_text;
  font-weight: 400;
}

.textoIcono{
  font-size: 14px;
  color: $color_title;
  font-weight: 400;
}

.textoLeyenda{
  font-size: 12px;
  color: $color_title;
  font-weight: 400;
  color: #333333;

}





// ----------------------------------------------------- Fin de fuentes






// MÁRGENES GLOBALES Y CONFIGURACIONES DEL BODY
div#root {
  margin-left: 300px !important;
  padding: 50px !important;

  background: #F5F5F5 !important;
  
  min-height: 100vh;
}

.general {
  height: 100% !important;
}



// ################################
// HEADERPAGE 

.headerpagebox{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

    .bloquetituloyaccion{
      display: flex;
      flex-direction: row;
      gap: 30px;

      .bloquetexto{
        color: $color_text;
        font-size: 18px;
        span{
          color: $color_title;
          font-weight: 700;
          font-size: 32px;
        }
      }


      Button{
        filter: $main_shadow;
        margin: 8px 0;
        border-radius: 8px;
        p{
          padding: 0 10px;
          margin: 0;
        }
      }

    }


    .bloquefiltro{
      
      background: white;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      padding: 8px 20px;
      filter: $main_shadow;
      align-items: center;

      p{
        padding: 0 15px;
        margin:0px;
        font-weight: 500;
      }
      
      .iconbox{

        // background-color: #fff0cd00;
        background-color: #fef0cd;
        // filter: $main_shadow;
        padding: 10px;
        border-radius: 15px;
        // border:1px solid #c3baba;
        
        display: flex;
        justify-content: center;
        align-items: center;

        .icon{
          font-size: 30px  !important;
          color: #F0B527;
        }

      }
    }


}




// END HEADERPAGE
// ################################



// ################################
// ################################
// COMPONENTES:
// ################################
// ################################

.listadoTarjetasBoxs{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
// Tarjeta
.tarjetaBox{ 

  &>div:last-child{
      padding-bottom: 0px;
      border-bottom: unset;
  } 

  background-color: white !important;
  border-radius: 15px;   

  display: flex;
  flex-direction: column;

  gap:20px;

  filter: $main_shadow; 
  padding: 20px;
}
.tarjetaSinBordeBox{
 

  background-color: white !important;
  border-radius: 15px;   

  display: flex;
  flex-direction: column;

  gap:20px;

  filter: $main_shadow; 
  padding: 20px;

}



// Seccion Interior Tarjeta


.seccionInteriorTarjeta{
    
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  border-bottom: 1px solid #b1b1b1;
  // padding-bottom: 80px;
  // padding-top: 50px;
  // padding-left: 30px;
  // padding-right: 30px;
  &:first-child{ 
      border-bottom: unset;
      padding-top: unset;
      padding-bottom: 10px;
      padding-left: unset;
      padding-right: unset;
  }
  &:nth-child(2){
      padding-top: unset;
  }
}



// BLOQUE DERECHO ICONOS



.bloquederechobox{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  border-left: 1px solid #b1b1b1;
  padding-left: 20px;
  margin-left: auto;

}





.CButton{
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
}
.Bedit{
    background: #d8d8d8;
    &:hover{ background: #a5a5a5;}
}
.Bdelete{
    background: #f39999;
    &:hover{ background: #a55d5d;}
}
.BDownload{
    background: #f0b527;
    &:hover{ background: #a27918;}
}




// AUXILIARES Y MÁRGENES:
.w100{
  width: 100%;
}