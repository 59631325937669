@import "../../styles/global.scss";

.contentBox{
    display: flex;
    flex-direction: row;
    // z-index: 1;
    // background-color: green;
    // padding: 10px;
    width: 100%;
    max-height: 430px;

}


.parteIzquierda{
    width: 70%;
    // z-index: 2;
    // background: blue;
    // overflow: scroll !important; 
}

.parteDerecha{
    width: 30%;
    // background: red;
    // overflow: scroll !important; 
    // z-index: 3;
} 