

// g.highcharts-axis.highcharts-xaxis.highcharts-radial-axis {
//     display: none !important;
// }
 
.SpiderBox g.highcharts-axis.highcharts-xaxis.highcharts-radial-axis {
    display: none !important;
}



.SpiderBox img{
    filter: drop-shadow(0px 1px 0px rgba(0,0,0,0.3));

}




.SpiderBox{
    width: 100%;
}