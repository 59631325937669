@import "../../styles/global.scss";


.bloquedatos{
    
    width: 100%; 
}
.tarjeta{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-bottom: 1px solid #e9e9e9;
    transition:  0.3s ease;

    &:hover{
        background-color: $color_main_list !important;
        div, strong, svg, path{
            // color: white !important;
            // fill: white !important;
        }
        border-radius: 8px; 
        transition:  0.3s ease;
        border-bottom:  1px solid transparent;
    }
}
.tarjetaActiva{ 
    background-color: $color_main !important;
    div, strong, svg, path{
        color: white !important;
        fill: white !important;
    }
    border-radius: 8px; 
    transition:  0.3s ease;
    border-bottom:  1px solid transparent;
}

.avatar{
    border: 1px solid #e5e5e5 !important;
    width: 50px;
    height: 50px;
}



.tarjetaInactiva{
    
}


.nombredeportista{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center; 

    svg{
        color: $color_title;
        font-size: 20px; 
    }
}



.bloqueiconos{
    display: flex ;
    flex-direction: row ; 
    flex-wrap: nowrap ;
    gap: 25px ; 
    
}