@import "../../styles/global.scss";

.listadoDeportistas{
    width: 100%;

    >div:nth-child(odd) {
  
        background: rgb(255, 250, 240);
    }
    
}