@import "../../styles/global.scss";




.pabajo{
    transform: rotate(180deg);
    transition: 500ms;
    
}
.parriba{
    transition: 500ms;
}


.noseleccionada{  
    transition: 500ms;
    > td{  
        transition: 500ms;
    }
    >td:last-child{ 
        transition: 500ms; 
    }
    code{
        transition: 500ms; 
    }
}


.seleccionada{ 
    
    transition: 500ms;
    
    filter: drop-shadow(2px 4px 6px rgba(255, 255, 255, 0));
    > td{ color: white;
        background-color: #999999;
        transition: 500ms;
    }
    >td:last-child{ 
        transition: 500ms;
        border-top-right-radius: 15px !important;
    }
    code{
        transition: 500ms; 
        border-top-left-radius: 15px !important;
        color: white;   
        background-color: #999999;
        
    }
     

}

.noseleccionada{ 


}
 
.filaSeleccionadaBox{
    transition: 500ms;
    background-color: #5d5d5d; 
    border-bottom-left-radius: 10px !important; 
    border-bottom-right-radius: 10px !important; 
    padding: 20px;
    code{ 
        transition: 500ms; 
        color: rgba(0, 0, 0, 0.87);   
        background-color: white;
        
    }
}


.titulosubtabla{
    transition: 500ms;
    color: white;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 5px !important;

}