@import "../../styles/global.scss";


.panelLateralBox{
    background-color: white !important;
    position: relative !important;
}
.customTP{
    position: relative;
    color:white !important;
    z-index: 3;
    filter: main_shadow;
}


.topBox{
    width: 300px;
    padding: 20px;
    background: $color_main_degradado_h !important; 
}

.myologoh{
    position: absolute;
    top: 11px !important;
    right: -19px !important;
    height: 74px !important;
    width: auto !important;
    z-index: 1 !important;
    opacity: 0.10;
}


.elementoTexto{ 
    
    font-weight: 500 !important;
    font-size: 16px; 
    color: #464255;
    transition: 0.3s;
}