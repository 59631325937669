@import "../../styles/global.scss";

.mainmainBox{
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.mainBox{
    display: flex;
    flex-direction: row;
        
    
    .boxGrupo{
        text-align: right;
        border-right: solid 1px black;
        min-width: 200px;
        padding-right: 30px;
        padding-left: 30px;

    }

    .boxSecciones{ 
        margin-left: 30px;
        width: 100%;

        .seccion{
            display: flex;
            flex-direction: column;
            gap:20px;
            
            .fila{ 
            }

            .filaConIcon{
                display: flex;
                flex-direction: row;
                gap:10px
            }
            
        }
    }
}





.iconbox{

    // background-color: #fff0cd00;
    background-color: #d8d8d8;
    // filter: $main_shadow;
    padding: 12px;
    border-radius: 8px;
    width: 33px;
    height: 33px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    // border:1px solid #c3baba;
    
    display: flex;
    justify-content: center;
    align-items: center;

    .icon{
      font-size: 25px  !important;
      color: #787878;
    }

  }
