@import "../../styles/global.scss";


.mainbox{
    
    &>div:last-child{
        padding-bottom: 0px;
        border-bottom: unset;
    } 
    background-color: white !important;
    border-radius: 15px; 
    padding: 20px;
    margin: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap:20px;
    filter: $main_shadow; 
}




.seccion{
    
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    border-bottom: 1px solid #b1b1b1;
    padding-bottom: 80px;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    &:first-child{ 
        border-bottom: unset;
        padding-top: unset;
        padding-bottom: 10px;
        padding-left: unset;
        padding-right: unset;
    }
    &:nth-child(2){
        padding-top: unset;
    }
}


.boxBody{
    width: 50%;
    max-width: 300px;
    
    
}

.datosbrutosbox{
    width: 100% !important;
    max-width: unset;
    background-color: green;

}

.bloquederechobox{
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-left: 1px solid #b1b1b1;
    padding-left: 20px;
    margin-left: auto;

}


.CButton{
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
}

.Bedit{
    background: #d8d8d8;
    &:hover{ background: #a5a5a5;}
}
.Bdelete{
    background: #f39999;
    &:hover{ background: #a55d5d;}
}
.BDownload{
    background: #f0b527;
    &:hover{ background: #a27918;}
}


.bloqueizquierdo , .bloquecentral{
    display: flex;
    flex-direction: column;
    p{
        padding: 0 !important;
        margin: 0 !important;
        font-size: 20px;
    }
    span{
        font-weight: 300;
        font-size: 13px;
      }
}

.bloquecentral{
    margin-left: 50px;
}
 

.tituloElemento{ 
    font-weight: 500;
    color: $color_title ;
    font-size: 18px !important; 
    text-align: center;
    width: 100%; 
    margin-bottom: 30px;
}


.leyenda{
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;

    &>div{
        border-left: 1px solid #b1b1b1;
        padding-left: 15px;
        &:first-child{ 
            border-left: unset;
        }
    }
}
.elementoLeyenda{
    display: flex;
    flex-direction: row;
    gap: 5px; 
    align-items: center;
    background-color: green($color: #000000);
    .circulo{
        width: 11px !important;
        height: 11px !important;
        background-color: rgb(138, 138, 138);
        border-radius: 100px;
        margin-top: auto;
        margin-bottom: auto;
        filter: drop-shadow(2px 2px 1px rgba(0,0, 0, 0.35));
    }
    .principal{
        background-color: rgb(230, 204, 90);
    }
    .secundario{
        background-color: rgb(230, 90, 90);
    }
}


.boxResumen{ 
}


 
.elementounicoenseccion{
    width: 100%; 
}

.boxResumen{
    width: 100%; 
}
.tituloConTabs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:50px;
    width: 100%;   
    height: fit-content;
    .tituloElemento{
        width: fit-content;
    }
     
    .boxpestañas{ 
        height: fit-content;
        // transform: scale(0.85) translate(0, -15px); 
        transform: translate(0, -8px); 


    }
}


.tituloConTabsIzq{
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap:50px;
    width: 100%;   
    height: fit-content;
    .tituloElemento{
        width: fit-content;
    }
     
    .boxpestañas{ 
        height: fit-content;
        // transform: scale(0.85) translate(0, -15px); 
        transform: translate(0, -8px); 


    }
}

.bloqueFuerzas{
    width: 100%; 
}

.bloqueDonuts{
    width: 50%; 
}


.bloqueComparativaGeneral{
    width: 50%; 
}

.sobreTituloSeccion{
    width: 100%;
    margin-bottom: -50px;
    padding-top: 50px;
    font-weight: 500;
    color: $color_title ;
    font-size: 18px !important; 
    text-align: center;
}




.topBodyBox{
    display: flex;
    flex-direction: row; 
    width: 100%;
    .polarBox{
        width: 50%;  
    }

    .resumenBodyBox{
        width: 50%;        
        display: flex;
        flex-direction: row;      
        gap: 15px;  
    }
}


.filaIconos{
    display: flex;
    gap: 20px;
}


.topResumenBox{
    width: 100%;
}






.filaIconos{
    display: flex;
    gap: 20px;
}



.resumenBodyBox{
    // width: 50%;        
    display: flex;
    flex-direction: row;      
    gap: 15px;  
}