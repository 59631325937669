  @import "../../styles/global.scss";


  .editprofile--button {
    height: 36px;
    width: 36px;  
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_main_degradado ;
    border:unset;
    filter: $main_shadow;
     
    overflow: hidden;
    position: relative; 
    transition: all 0.28s;

  } 

  .editprofile--button:hover {    
    transform: scale(1.08);
    transition: all 0.28s;
  }


  .img-1 {
    height: 18px;
    width: 18px;  
  }
  