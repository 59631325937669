@import "../../styles/global.scss";

.cabecera{
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // filter: $main_shadow;
    gap: 5px;
    width: 100%;
}
.mainbox{
    transition: 300ms;
    // background-color: white;
    // border-radius: 8px; 
    display: flex;
    flex-direction: column; 
    // filter: $main_shadow; 
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    &:nth-child(even) {
        background: $color_alterno;
    }
    &:last-child{
        border-bottom: unset;
    }
    &:nth-child(2){
        border-top: 1px solid #e5e5e5 !important;
    }


    
}
 

.bloqueizquierdobox{ 
    // min-width: fit-content !important; 
    
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;  
  width: 100%;
    strong{
        white-space: nowrap;  
        overflow: hidden;
        text-overflow: ellipsis; 
        font-size: 18px;
        font-weight: 700;
        color: $color_title; 
    }
    p{
        margin: 0 !important;
        padding: 0 !important;
    } 
} 

.bloquederechobox{
    display: flex;
    flex-direction: row; 
    align-items: center;
}



.bloquecentralbox{
    display: flex;
    justify-content: space-around;
    // background-color: red;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.datoresumenbox{
    // background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    // min-width: 130px;
    margin-left: 15px;
    margin-right: 15px;

    .eldato{
        font-size: 16px;
        color: $color_title;
        font-weight: 300;  
        min-width: fit-content !important;
        white-space: nowrap;
        b{ 
            font-weight: 700;  
        }    
        text-align: center;
    }
    .laleyenda{
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        b{
            font-size: 11px; 
            font-weight: 500;
            font-style: italic;
        }    
    }

}

 


.iconomodo{
    // border: 1px solid #e5e5e5 !important;
    width: 50px !important;
    height: 50px !important;  
    min-width: 50px !important;
    min-height: 50px !important;   
}


.pabajo{
    transform: rotate(180deg);
    transition: 500ms;
}
.parriba{
    transition: 500ms;
}


.tarjetaactiva{
    transition: 300ms;

    background-color: #999999 !important;
    border-radius: 8px;
}

.cabeceraactiva{
    transition: 300ms;
    p{color: white !important;}
    strong{ color:white !important}
    b{color:white !important}
    div.bloquecentralbox div{ color: white !important}

}


.pendienteAct{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 15px;
    font-weight: 500;
    color: rgb(137, 19, 19);
}

