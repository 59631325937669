@import "../styles/global.scss";

th{
    background: #efbc21;
    color: white !important;
    font-weight: 600 !important;
    padding: 5px 16px !important;
    &:first-of-type{ border-top-left-radius: 10px !important;}
    &:last-of-type{ border-top-right-radius: 10px !important; }
}

td{
    background-color: white;
}

.toptable{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    margin-bottom: 20px;
}

.boxbotoneraselectorvista{
    display: flex;
    flex-direction: row; 
    height: fit-content; 
    align-items: center;
    gap: 15px;

    p{
        margin:0;
        padding: 0; 
        color: $color_text;
    }

}


.bodyimagebox{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: -16px 0;
    padding-top: 16px;
}
.bodyimage{
    display: flex;
    justify-content: center;
    width: 120px !important;
    position: relative; 
    pointer-events: none;

    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 50%);      
        pointer-events: none;
    }
}


.contenido{
    display: flex;
    flex-direction: row; 
    width: 100%; 
}

.listadoDeportistas{ 
    display: flex;
    flex-direction: column; 
    position: sticky;
    top: 0; 
    height: fit-content; 
    min-width: fit-content;
    background-color: white; 
    p{
        padding: 25px;
        margin-right: 30px;
        padding-bottom: 10px;
    }
    border-radius: 8px;
    margin-right: 30px;
    filter: $main_shadow;
}

.listadoReports{
    
    position: sticky;
    top: 0; 
    height: fit-content;
    border-left: 1px solid #c1c1c1;
    padding-left: 25px; 
    width: 100%;
    p{ margin-bottom: 0; font-size: 18px; }
}


.boxlistreport{
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;  
}