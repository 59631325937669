  @import "../../styles/global.scss";

 
.menulistbox{
  display: flex;
  flex-direction: column;
  gap:20px;
  background-color: purple;    
  margin-left: -20px; 
}


.menumainitembox{ 
  background-color: red !important;
  display: flex;
  flex-direction: row;
  gap: 20px;

}

.columnacolor{
  width: 8px !important; 
  background-color: orange;
  display: inline-block;
  border-radius: 0 8px 8px 0;
}

.icontextbox{
  display: flex;
  flex-direction: row;
  align-items: center; 
  height: 55px;
}