@import "../../styles/global.scss";



.flotantemainbox{
    padding: 30px; 
    border-radius: 15px !important;
    
    p{
        margin-bottom: 10px;
    }
 
    // overflow: hidden;
}

.ctitulpagina{
    margin-right: 50px;
    margin-bottom: 30px !important;
    width: 600px !important;
}


.selectorBotonera{
    background-color: #828282;
    border-radius: 300px; 
    width: fit-content; 
    
    display: flex;
    flex-direction: row;
    padding: 8px 10px;
    gap: 30px; 
}


.tipoSeleccionado{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color_main;
    border-radius: 200px;
    padding: 5px 25px;
    color: white;
    filter: drop-shadow(2px 2px 0px #937227)
}

.tipoSinSeleccionar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    border-radius: 200px;
    padding: 5px 25px; 
    color: white;

}

.deshabilitado{
    pointer-events: none;
    filter: blur(1.5px);
}

.selectorBodys{
    width: 100%;
    >div{
        width: 100%;
    }
}

.oculto{
    filter: opacity(0);
}




// cosas de steppers

.mtituloElemento span{
    
  font-size: 18px !important;
  color: $color_title !important;
  font-weight: 700 !important;
}