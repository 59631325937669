  @import "../../styles/global.scss";

  
  .mainbox {
    display: flex;
    flex-direction: row;
    align-items: start; /* Alinea verticalmente los elementos */
    margin-bottom: 20px;
    height: 150px;
    /* Aplicar margen entre los tres primeros elementos */
    > :not(.botonerabox) {
      margin-right: 20px;
    }
  }

  .headComponent {
    width: 450px; /* Establece un ancho fijo para headComponent */
    /* O, si prefieres un tamaño máximo: */
    max-width: 450px;
  }
  
  .botonerabox {
    margin-left: auto; /* Empujar a la derecha usando margen automático */
    display: flex;
    gap: 15px;
    align-items: start;
    justify-content: center;

    /* Resto de tus estilos para .botonerabox */
  }

  .separador{  
    width: 1px;
    background-color: #D0D6DE;
  }

  .iconbox{

    // background-color: #fff0cd00;
    background-color: #d8d8d8;
    // filter: $main_shadow;
    padding: 12px;
    border-radius: 15px;
    // border:1px solid #c3baba;
    
    display: flex;
    justify-content: center;
    align-items: center;

    .icon{
      font-size: 30px  !important;
      color: #787878;
    }

  }


  .barrabusqueda{
     
    >div{
      border-radius: 8px !important;
      background-color: white;
    }

    >div.Mui-focused{
      color: orange !important;
    }
  }
 


.hidden{
  opacity: 0;
}