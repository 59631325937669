@import "../../../styles/global.scss";


.iconbox{
    display: flex;
    flex-direction: column;
    width: fit-content; 
    justify-content: center;
    margin: 0;
    


    p{
        margin:0 !important;
        padding: 0 !important;
        text-align: center;
    }

    .boxsuperior{
        display: flex;
        flex-direction: row;
        gap: 5px;

    }
    strong{
        font-size: 16px;
        color: $color_advanceicons;
        font-weight: 600;
    }
    svg{
        width: 20px;
        path{
            fill: $color_advanceicons;
        }
    }
}

