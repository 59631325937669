
.modobox{
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    min-width: 65px  !important;
    width: 65px !important;
    min-height: 65px !important;
    height: 65px !important;
    justify-content: center;
    p{
        margin: 0 !important;
        padding: 0 !important;
        font-weight: 500;
        font-size: 13px;
    }
    margin-right: 5px;
}
.boxiconomodo{ 
    
    background-color: #bdbcbc;
    border-radius: 500px !important;
    padding: 10px;
    min-width: 50px  !important;
    width: 50px !important;
    min-height: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        fill: white;
    }
}