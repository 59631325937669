@import "../../styles/global.scss";


// SubscriptionCard.module.scss
.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px; 
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
  
    &:hover {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    }
    

  background-color: white !important;
  border-radius: 15px;   

  display: flex;
  flex-direction: column;

  gap:20px;

  filter: $main_shadow; 
  padding: 20px;

  }
  
  .cardTitle {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .cardPrice {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .featuresList {
    list-style-type: none;
    padding: 0;
  
    .featureItem {
      margin-bottom: 10px;
    }
  }
  