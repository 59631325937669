@import "../../styles/global.scss";

.br{
    background-color: red !important;
    background: red !important;
    border: 1px solid green !important;
} 
 
.mainbox{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    
    /* DEBUGCOLOR
    background: red;
    /* */
    padding: 20px 0;

}
 

.copyright{
    font-size: 14px;
    padding: 15px 0 0 15px; 
    color: $color_text;
    margin-left: 20px ;
    
    span{
        font-weight: bold;
    } 
}


.copyright{
    font-size: 14px;
    padding: 15px 0 0 15px;
    color: $color_text;
    
    span{
        font-weight: bold;
    } 
}


.boxmenu{
    
    /* DEBUGCOLOR
    background-color: green;
    /* */
    padding: 10px 20px;
}


.myologoh{
    width: 100%;
    padding: 10px 20px 0;
}


.boxuserimage{
    
    /* DEBUGCOLOR
    background: blue; 
    /* */
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.boxedit{
    position: absolute;
    right: 0;
    bottom: 0;
}

.imagenIcon{
    position: relative;

}

.menulistbox{
    display: flex;
    flex-direction: column;
    gap:20px;
    
    /* DEBUGCOLOR
    background-color: purple;    
    /* */
    margin-left: -20px; 
    
}


.menumainitembox{ 
    /* DEBUGCOLOR
    background-color: red !important;
    /* */
    display: flex;
    flex-direction: row;
    gap: 20px;
 
}

.columnacolor{
    width: 8px !important; 
    background-color: $color_main;
    display: inline-block;
    border-radius: 0 8px 8px 0;
}

.icontextbox{
    display: flex;
    flex-direction: row;
    align-items: center; 
    height: 55px;
}


// Arreglos de los elementos del menu

 
.menulistbox{
    display: flex;
    flex-direction: column;
    gap:10px;
    /* DEBUGCOLOR 
    background-color: purple;    
    /* */
    margin-left: -20px; 
  }
  
  
  .menumainitembox{ 
    
    /* DEBUGCOLOR
    background-color: yellow !important;
    /* */
    display: flex;
    flex-direction: row;
    gap: 35px;
    padding-right: 10px;
  
  }
  
  .columnacolor{
    width: 8px !important; 
    background-color: orange;
    display: inline-block;
    border-radius: 0 8px 8px 0;
  }
  
  
  .icontextboxActive{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    background: $color_main_degradado_h !important; 
    border-radius: 8px;
    padding-left: 30px;
    transition: 0.3s;

    .itemicon, .itemtext{
        color: white;
        transition: 0.3s;
    }
  }
  
  .icontextbox{
    
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;  
    border-radius: 8px;
    
    transition: 0.3s;

    &:hover{
        
    background: $color_main_degradado_h !important; 
    transition: 0.3s;
    border-radius: 8px;
     
    .itemicon, .itemtext{
        color: white;
    }

    }

  }
  
  .itemicon{
    color: $color_title;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s;
}

.itemtext{
    
    font-weight: 500 !important;
    font-size: 16px;
    padding-left: 15px;
    color: $color_title;
    transition: 0.3s;
}

  .null{}
  